export default {
  wallet:'Connect Wallet',
  card:'Bank Card',
  coldWallet:'Cold Wallet',
  shop:'Merchant Mall',
  mine:'My Personal',
  wTips:'Please use the Binance Smart Chain network to connect',
  applyCard:'Apply for the Meta Genesis Card',
  choose:'Select my card',
  balance:'My Balance',
  renew:'Refresh Balance',
  recharge:'Top Up',
  changePsw:'Change Password',
  auth:'Authentication',
  rRecord:'Top Up Records',
  recharging:'Top Up in Progress',
  rSuccess:'Top Up Successful',
  more:'More Details',
  noRecord:'No Records',
  model:'Select Model',
  rAddress:'Receiving Address',
  name:'Name',
  postCode:'Postal Code',
  mobile:'Mobile Number',
  email:'Email',
  address:'Address',
  addAddress:'Please add Receiving Address',
  tips:'Initial Password will be the last 6 digit of your mobile number',
  applyNum:'Application Amount',
  buyNum:'Purchase Quantity',
  applyCost:'Application Costs',
  payCost:'Costs Payment',
  cApply:'Confirm Application',
  cBuy:'Confirm Purchase',
  add:'Add ',
  modify:'Modify ',
  tips1:'Please enter all text in English ',
  tips2:' Ensure details are filled in',
  country:'Country/Area',
  area:'City',
  pCode:'Postal Code',
  contact:'Contact Name',
  phone:'International Mobile Number',
  email1:'Email Address',
  tips3:'Please use the email provided to send emails and receive latest updates',
  cSubmit:'Confirm Submission',
  loading:'Loading',
  toast1:'Please choose Country or Area',
  toast2:'Please enter City',
  toast3:'Please enter postal code',
  toast4:'Please Enter Address',
  toast5:'Please enter contact name',
  toast6:'Please enter mobile number',
  toast7:'Please enter email',
  doFail:'Operation Failed',
  selectAdd:'Select Receiving Address',
  cSelect:'Confirm Selection',
  apply:'Apply Bank Card',
  doSuccess:'Operation Successful',
  noUSDT:'USDT balance insufficient',
  noLAND:'$LAND balance insufficient',
  noAMA:'$AMA balance insufficient',
  chooseCard:'Select Bank Card',
  cardInfo:'Card Info',
  balance1:'Balance',
  bRecharge:'Balance Recharge',
  cardNumber:'Card Number',
  rechargeNum:'Top Up Amount',
  single:'Single Top Up ',
  cRecharge:'Confirm Top Up',
  tips4:'Enter Top Up Amount',
  enter:'Please Enter ',
  enterPsw:'Enter Password',
  placeholder:'Please enter your 6 digit password',
  confirmPsw:'Confirm Password',
  cModify:'Confirm Edit',
  mRecord:'Edit Records',
  modifying:'Editing',
  modifySuc:'Edit Successful',
  finish:'Complete',
  toast8:'Please enter password',
  toast9:'Please confirm password',
  toast10:'Password Different',
  verified:'Verify Personal Details',
  passport:'Passport Number',
  period:'Document validity',
  nationality:'Nationality',
  profession:'Profession',
  position:'Position',
  mobile1:'International Mobile Number',
  birth:'Birth Date',
  email2:'Email',
  address1:'Residential Address',
  apply1:'Application type and details',
  purpose:'Application purpose',
  sign:'Applicant signature',
  tips0:'Please write your name on a white paper and take a photo to upload',
  cAuth:'Confirm Verify',
  complete:'KYC Completed',
  toast11:'Please enter name',
  toast12:'Enter ID number / Passport number',
  toast13:'Enter document validity',
  toast14:'Select Nationality',
  toast15:'Enter Job / Profession',
  toast16:'Enter Mobile Number',
  toast17:'Enter birth date',
  toast18:'Enter Email Address',
  toast19:'Enter residential address',
  toast20:'Enter Application purpose',
  toast21:'Upload photo of Signature',
  tips5:'File too big',
  copySuc:'Copy Successful',
  copyFailed:'Copy Fail',
  buyCold:'Purchase cold wallet',
  tutorial:'Tutorial',
  website:'Cold wallet Official Site',
  noData:'No Data',
  detail:'Detailed Introduction',
  buy:'Purchase Now',
  aList:'Address List',
  addAddress1:'Enter receiving address',
  dAddress:'Confirm address',
  eAddress:'Edit address',
  vAddress:'View full address',
  myOrder:'My Orders',
  wait:'Awaiting Shipment',
  shipped:'Shipment Sent',
  cDetail:'Enquire more details',
  oDetail:'Order Information',
  type:'Type',
  goodsName:'Product Name',
  model1:'Model Number',
  num:'Quantity',
  status:'Order details',
  company:'Courier Company',
  number:'Tracking number',
  notShipped:'Not Shipped products',
  buyGoods:'Purchase Items',
  prompt:'Hint',
  tips6:'Confirm remove this receiving address?',
  payType: 'Payment Currency',
  confirm: 'Confirm',
  cancel: 'Cancel',

  applyInfo:'Card Application Details',
  viewInfo: 'Check Details',
  editInfo: 'Edit Details',
  authing:'Authenticating',
  addInfo: 'Add Additional Details',
  editApplyInfo: 'Edit Application Details',
  fullName: 'Full Name',
  city: 'City',
  uploadPassport: 'Upload Passport Photo',
  tips11: 'Please Upload your Passport Photo',
  tips12: 'Enter Full name',
  tips13: 'Enter Passport No',
  tips14: 'Enter Industry',
  tips15: 'Enter Job Title',
  tips16: 'Enter Mobile Number',
  tips17: 'Enter Postal Code',
  tips18: 'Enter City',
  tips19: 'Please Upload your Passport Photo',
  applyDetail: 'Application',
  tips20: 'Enter Application Details',
  myAmount: 'My Amount',
  tips21: 'Amount can only be 1 during payment of NFT',

  vTitle: 'Meta Genesis Card Tutorial Video',
  a0: 'Province',
  a1: 'Please fill in the province',
  a2: 'Please select an international area code'
}
