import { createI18n } from "vue-i18n";

import zh from './lang/zh'
import en from './lang/en'

const i18n = createI18n({
  locale: sessionStorage.getItem('lang') || 'en',
  messages: {
    zh,
    en
  }
})

export default i18n;
