<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less" scoped>
@import "./assets/css/base.less";
</style>
