import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/connect',
    component: () => import('../views/Connect/Index'),
    meta: {
      title: 'Connect'
    }
  },
  {
    path: '/',
    component: () => import('../views/Index/Index'),
    meta: {
      title: 'GENESIS'
    }
  },
  {
    path: '/card',
    component: () => import('../views/Card/Index'),
    meta: {
      title: 'Card Activation'
    }
  },
  {
    path: '/card/add',
    component: () => import('../views/Card/Add')
  },
  {
    path: '/card/detail',
    component: () => import('../views/Card/Detail'),
    meta: {
      title: 'Card Details'
    }
  },
  {
    path: '/myOrder',
    component: () => import('../views/MyOrder/Index')
  },
  {
    path: '/myOrder/detail',
    component: () => import('../views/MyOrder/Detail')
  },
  {
    path: '/mine',
    component: () => import('../views/Mine/Index')
  },
  {
    path: '/addAddress',
    component: () => import('../views/Mine/AddAddress')
  },
  {
    path: '/product',
    component: () => import('../views/Product/Index')
  },
  {
    path: '/selectAddress',
    component: () => import('../views/Product/SelectAddress')
  },
  {
    path: '/success',
    component: () => import('../views/Product/Success')
  }
]

const router = createRouter({
  history: createWebHashHistory(),      // history必须指定
  routes
})

router.beforeEach((to, from, next ) => {
  if(!sessionStorage.getItem('address') && to.path !== '/connect') {
    return next('/connect')
  }
  next()
})

export default router;
