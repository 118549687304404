import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";
import 'vant/lib/index.css'
import './assets/css/base.less'
import 'amfe-flexible'
import i18n from "./i18n";

import { Popup, RadioGroup, Radio, Uploader, Picker, Swipe, SwipeItem,
Image as VanImage, NumberKeyboard, Loading, Popover, NavBar, Search } from 'vant'

const app = createApp(App)
app.use(router);
app.use(i18n);
app.use(Popup).use(RadioGroup).use(Radio).use(Uploader).use(Picker)
  .use(Swipe).use(SwipeItem).use(VanImage).use(NumberKeyboard)
  .use(Loading).use(Popover).use(NavBar).use(Search)

app.mount('#app')
