export default {
  wallet: '链接钱包',
  card: '银行卡',
  coldWallet: '冷钱包',
  shop: '物料商城',
  mine: '我的',
  wTips: '请使用币安智能链链接',
  applyCard: '申请乌托邦银行卡',
  choose: '选择我的卡',
  balance: '我的余额',
  renew: '更新余额',
  recharge: '充值',
  changePsw: '修改密码',
  auth: '实名',
  rRecord: '充值记录',
  recharging: '充值中',
  rSuccess: '充值成功',
  more: '查看更多',
  noRecord: '暂无记录',
  model: '选择型号',
  rAddress: '收货地址',
  name: '姓名',
  postCode: '邮编',
  mobile: '电话',
  email: '邮箱',
  address: '地址',
  addAddress: '请添加收货地址',
  tips: '初始密码为手机号后6位',
  applyNum: '申请数量',
  buyNum: '购买数量',
  applyCost: '申请费用',
  payCost: '支付费用',
  cApply: '确认申请',
  cBuy: '确认购买',
  add: '添加',
  modify: '修改',
  tips1: '请用英文填写所有字段。',
  tips2: '必需的表示必填字段',
  country: '国家或地区',
  area: '地区',
  pCode: '邮政编码',
  contact: '联系人姓名',
  phone: '(国际区号)电话',
  email1: '电子邮件',
  tips3: '注：使用上面提供的电子邮件发送此货件的状态更新信息',
  cSubmit: '确认提交',
  loading: '加载中',
  toast1: '请选择国家或地区',
  toast2: '请填写地区',
  toast3: '请填写邮政编码',
  toast4: '请填写地址',
  toast5: '请填写联系人姓名',
  toast6: '请填写电话',
  toast7: '请填写电子邮件',
  doFail: '操作失败',
  selectAdd: '选择收货地址',
  cSelect: '确认选择',
  apply: '申请银行卡',
  doSuccess: '操作成功',
  noUSDT: 'USDT余额不足',
  noLAND: 'LAND余额不足',
  noAMA: 'AMA余额不足',
  chooseCard: '选择银行卡',
  cardInfo: '银行卡信息',
  balance1: '余额',
  bRecharge: '余额充值',
  cardNumber: '卡号',
  rechargeNum: '充值数量',
  single: '单笔充值',
  cRecharge: '确认充值',
  tips4: '请输入充值数量',
  enter: '请输入',
  enterPsw: '输入密码',
  placeholder: '请输入6位数字密码',
  confirmPsw: '确认密码',
  cModify: '确认修改',
  mRecord: '修改记录',
  modifying: '修改中',
  modifySuc: '修改成功',
  finish: '完成',
  toast8: '请输入密码',
  toast9: '请确认密码',
  toast10: '两次输入的密码不一致',
  verified: '实名认证',
  passport: '护照号码',
  period: '证件有效期',
  nationality: '国籍',
  profession: '行业',
  position: '职位',
  mobile1: '(国际区号)手机号码',
  birth: '出生日期',
  email2: '电邮',
  address1: '住址',
  apply1: '申请事项及资料',
  purpose: '开户目的',
  sign: '申请人签署',
  tips0: '请将姓名在白纸上书写后拍照上传',
  cAuth: '确认认证',
  complete: '实名已完成',
  toast11: '请填写姓名',
  toast12: '请填写身份证/护照号码',
  toast13: '请填写证件有效期',
  toast14: '请选择国籍',
  toast15: '请填写职业/行业',
  toast16: '请填写手机号码',
  toast17: '请填写出生日期',
  toast18: '请填写电邮',
  toast19: '请填写住址',
  toast20: '请填写开户目的',
  toast21: '请上传申请人签名的照片',
  tips5: '文件太大',
  copySuc: '复制成功',
  copyFailed: '复制失败',
  buyCold: '购买冷钱包',
  tutorial: '使用教程',
  website: '冷钱包官网',
  noData: '暂无数据',
  detail: '详情介绍',
  buy: '立即购买',
  aList: '地址列表',
  addAddress1: '添加收货地址',
  dAddress: '默认地址',
  eAddress: '修改地址',
  vAddress: '查看完整地址',
  myOrder: '我的订单',
  wait: '待发货',
  shipped: '已发货',
  cDetail: '查看详情',
  oDetail: '订单详情',
  type: '类型',
  goodsName: '商品名称',
  model1: '型号',
  num: '数量',
  status: '订单状态',
  company: '快递公司',
  number: '快递单号',
  notShipped: '未发货',
  buyGoods: '购买商品',
  prompt: '提示',
  tips6: '确认删除此收货地址吗？',
  payType: '支付币种',
  confirm: '确认',
  cancel: '取消',

  applyInfo: '银行卡申请资料',
  viewInfo: '查看资料',
  editInfo: '编辑资料',
  authing: '审核中',
  addInfo: '添加申请资料',
  editApplyInfo: '编辑申请资料',
  fullName: '全名',
  city: '城市',
  uploadPassport: '上传护照照片',
  tips11: '请将你的护照拍照上传',
  tips12: '请填写全名',
  tips13: '请填写护照号码',
  tips14: '请填写行业',
  tips15: '请填写职位',
  tips16: '请填写电话号码',
  tips17: '请填写邮箱',
  tips18: '请填写城市',
  tips19: '请上传护照照片',
  applyDetail: '申请资料',
  tips20: '请先添加申请资料',
  myAmount: '我的数量',
  tips21: 'NFT支付时只能填写数量1',

  vTitle: '银行卡操作视频',
  a0: '省份',
  a1: '请填写省份',
  a2: '请选择国际区号'
}
